<template>
  <v-card class="pa-5 my-3 primary-border" rounded="lg" flat color="transparent">
    <v-row dense>
      <v-col v-if="tableIndex > 0" cols="12" md="12">
        <v-btn @click="$emit('removeTable')" color="primary" class="mr-2" dense rounded outlined>
          Remover tabela
          <v-icon class="ml-2" small>fas fa-trash</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="tableIndex > 0" cols="12" md="6">
        <v-text-field
          v-if="tableIndex > 0"
          v-model="costTable.title"
          label="Nome da tabela do Fornecedor*"
          placeholder="Nome da tabela"
          :rules="[() => !!costTable.title || 'Este campo é obrigatório']"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col v-else class="pb-4" cols="12" md="2">
        <v-switch
          v-model="costTable.is_fixed"
          :disabled="costTable.rows.length > 1"
          class="mt-0"
          label="Custo fixo"
          inset
          hide-details
        />
      </v-col>
      <v-col cols="12" md="12">
        <CostTableInputs
          v-for="(row, idx) in costTable.rows"
          :key="idx"
          :row="row"
          :rows="costTable.rows"
          @removeRow="removeRow(row)"
          :option="true"
          :isFixed="costTable.is_fixed"
          :tableIndex="tableIndex"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          v-show="!costTable.is_fixed"
          @click="costTable.rows.push({})"
          icon
          dark
          class="primary"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    costTable: {
      type: Object,
    },
    print_types: Array,
    tableIndex: Number
  },
  components: {
    CostTableInputs: () => import("./CostTableInputs"),
  },
  data: () => ({
    isDisabled: false,
    printTypes: [],
  }),
  methods: {
    removeRow(row) {
      let idx = this.costTable.rows.indexOf(row)
      this.costTable.rows.splice(idx, 1)
    },
    getPrints() {
      this.$api
        .get("product_prints/products")
        .then((res) => {
          this.print_types.map(print => {
            this.printTypes.push(res.data.data.find(value => value.id == print));
          })
          console.log(this.printTypes)
        })
        .catch((err) => {
          console.log(err);
        })
    },
  },
  created() {
    this.getPrints()
  }
};
</script>
<style scoped>
  .primary-border {
    border: 1px solid var(--v-primary-base)!important;
  }
</style>